import React from "react"
import styled from "@emotion/styled"
import Project from "../projects/project"
import Flex from "../projects/templateflex"
import Image from "../components/image"
import { Helmet } from "react-helmet"

const Title = styled.h2`
  color: black;

  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 20px;
  flex: 0 0 95%;
  margin-left: 2.5%;
`
const SubTitle = styled.h3`
  color: black;

  font-size: 20px;
`
const Desc = styled.p`
  flex: 0 0 95%;
  margin-left: 2.5%;
  margin-bottom: 30px;
`
const Text = styled.p`
  height: auto;
`
const TextBox = styled.div`
  flex: 0 0 42.5%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(6) {
    order: 6;
  }
  &:nth-child(7) {
    order: 7;
  }
  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(6) {
      order: 5;
    }
  }
`

const Img = styled.div`
  border: 0.01px #dcdcdc solid;
  box-shadow: 0px 18px 25px rgba(0, 0, 0, 0.15);
  flex: 0 0 42.5%;
  margin-bottom: 30px;
  &:nth-child(4) {
    order: 4;
  }
  &:nth-child(5) {
    order: 5;
  }
  &:nth-child(8) {
    order: 8;
  }

  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(5) {
      order: 6;
    }
  }
`
const Iconoclasm = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Portfolio- Iconoclasm</title>
      <link rel="canonical" href="https://avtar.dev/iconoclasm" />
    </Helmet>
    <Project>
      <Flex>
        <Title>Iconoclasm</Title>
        <Desc>
          This project was a static website that presents a custom iOS 14 icon
          set called Iconoclasm. It is built with the Gatsby framework. The
          creative side to building this website was a nice change of pace.
        </Desc>

        <TextBox>
          <SubTitle>CSS & Layout</SubTitle>
          <Text>
            I took on this project after doing a Frontend Masters course by Jen
            Kramer. It helped me solidify and build on what I learned at
            Concordia. Through this project, I had the opportunity to practice
            my CSS skills more. The main challenge I worked through was with
            layout and positioning. It required a good understanding of the
            box-model and how to manipulate it with flexboxes and grids.
          </Text>
        </TextBox>
        <Img>
          <Image
            fileName="iconoclasm-1.png"
            style={{ width: "100%" }}
            alt="grid layout on phone"
          />
        </Img>

        <Img>
          <Image
            fileName="iconoclasm-2.png"
            style={{ width: "100%" }}
            alt="graphql query of images"
          />
        </Img>
        <TextBox>
          <SubTitle>Optimization</SubTitle>
          <Text>
            We created all the icon designs from scratch and at a large image
            size to keep them high-res when exported. This made the image files
            for the website inadverdantly large. I tested it in Lighthouse and
            saw a lot of room for improvement. Using gatsby-image to help with
            load times and lazy loading some components improved numbers by
            quite a bit! My goal is to learn more about web app optimization and
            accessibility.
          </Text>
        </TextBox>

        <TextBox>
          <SubTitle>Future Improvements</SubTitle>
          <Text>
            Gumroad was used as the digital delivery and payment service. It was
            a quick and convenient option to get the icons out on the market. I
            would eventually like to build my own checkout using a service like
            Stripe. Let me know if you have any suggestions for a more
            streamlined approach to digital delivery!
          </Text>
        </TextBox>
        <Img>
          <Image
            fileName="iconoclasm-3.png"
            style={{ width: "100%" }}
            alt="landing page"
          />
        </Img>
      </Flex>
    </Project>
  </>
)

export default Iconoclasm
